import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './LayersTree.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const LayersTreeIconComponent: FC<IconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_918_861)">
        <path
          d="M1.66663 10.0208L9.70182 14.0384C9.81114 14.0931 9.86579 14.1204 9.92313 14.1312C9.97391 14.1407 10.026 14.1407 10.0768 14.1312C10.1341 14.1204 10.1888 14.0931 10.2981 14.0384L18.3333 10.0208M1.66663 14.1875L9.70182 18.2051C9.81114 18.2598 9.86579 18.2871 9.92313 18.2979C9.97391 18.3074 10.026 18.3074 10.0768 18.2979C10.1341 18.2871 10.1888 18.2598 10.2981 18.2051L18.3333 14.1875M1.66663 5.85418L9.70182 1.83658C9.81114 1.78193 9.86579 1.7546 9.92313 1.74384C9.97391 1.73431 10.026 1.73431 10.0768 1.74384C10.1341 1.7546 10.1888 1.78193 10.2981 1.83658L18.3333 5.85418L10.2981 9.87178C10.1888 9.92644 10.1341 9.95377 10.0768 9.96452C10.026 9.97405 9.97391 9.97405 9.92313 9.96452C9.86579 9.95377 9.81114 9.92644 9.70182 9.87178L1.66663 5.85418Z"
          stroke={color}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_918_861">
          <rect width="20" height="20" transform="translate(0 0.020874)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const LayersTreeIcon = withStyles(styles)(LayersTreeIconComponent);
