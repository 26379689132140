import { SvgIcon } from '@material-ui/core';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import styles from './NoDataIcon.styles';

export type NoDataIconProps = WithStyles<typeof styles>;

const NoDataIconComponent: React.FC<NoDataIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      height="156"
      viewBox="0 0 242 156"
      width="242"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      classes={{ root: classes.root }}
    >
      <linearGradient id="a">
        <stop offset="0" stopColor="#f6f7f9" />
        <stop offset="1" stopColor="#f6f7f9" />
      </linearGradient>
      <linearGradient id="b" x1="50%" x2="30.215993%" xlinkHref="#a" y1="58.434311%" y2="32.690522%" />
      <linearGradient id="c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0" stopColor="#e8ebef" stopOpacity=".75183" />
        <stop offset="1" stopColor="#f6f7f9" stopOpacity=".048596" />
      </linearGradient>
      <linearGradient id="d" x1="50%" x2="28.209186%" xlinkHref="#a" y1="56.097254%" y2="38.049621%" />
      <g fill="none" fillRule="evenodd" transform="translate(0 1)">
        <path
          d="m56 113c-.6003604-6.646431-6.0853333-11.852408-12.770018-11.852408-.9171892 0-1.8111712.101716-2.6738739.28768-.789045-6.4440275-6.1771531-11.435272-12.7205766-11.435272-5.9793874 0-10.9891171 4.1723845-12.4118198 9.8088537-.8374775-.1746627-1.7052252-.2681586-2.5931531-.2681586-7.08627031 0-12.8305586 5.8501739-12.8305586 13.0647729 0 .133565.01614414.261994.02018018.394532z"
          fill="url(#b)"
          opacity=".924456"
        />
        <path
          d="m183.689201 154h-123.6966516c-2.2486091 0-4.0543409-1.861142-3.9909289-4.114688l8.3452185-93.4142062c.0543531-1.9327633 1.6336135-3.4711058 3.5631496-3.4711058h22.4810584c1.0719645 0 2.0976282.4317446 2.8485066 1.1963765l2.474074 2.5218729c1.7070909 1.741101 4.0392428 2.7216052 6.4740614 2.7216052h89.817541c2.253641 0 4.06038 1.8661859 3.992942 4.1237665l-8.318042 86.5557209c-.065425 2.161749-1.832909 3.880658-3.990929 3.880658"
          fill="#fefefe"
        />
        <path
          d="m183.689201 154h-123.6966516c-2.2486091 0-4.0543409-1.861142-3.9909289-4.114688l8.3452185-93.4142062c.0543531-1.9327633 1.6336135-3.4711058 3.5631496-3.4711058h22.4810584c1.0719645 0 2.0976282.4317446 2.8485066 1.1963765l2.474074 2.5218729c1.7070909 1.741101 4.0392428 2.7216052 6.4740614 2.7216052h89.817541c2.253641 0 4.06038 1.8661859 3.992942 4.1237665l-8.318042 86.5557209c-.065425 2.161749-1.832909 3.880658-3.990929 3.880658z"
          stroke="#bfc5d1"
          strokeWidth="1.5"
        />
        <path d="m107.77544 12-40.77544 75h115l-42.708282-75z" fill="url(#c)" />
        <path
          d="m59.3514822 154c-2.1064005 0-3.850496-1.642141-3.9863604-3.753176l-4.3567326-80.9792785c-.1479411-2.3121345 1.6796858-4.2675455 3.9863604-4.2675455h123.6546634c2.106401 0 3.850496 1.642141 3.985354 3.7531764l4.356733 80.9782676c.148948 2.311124-1.678679 4.268556-3.985354 4.268556z"
          fill="#fefefe"
        />
        <path
          d="m183.006146 154h-123.6546638c-2.1064005 0-3.850496-1.642141-3.9863604-3.753176l-4.3567326-80.9792785c-.1479411-2.3121345 1.6796858-4.2675455 3.9863604-4.2675455h123.6546634c2.106401 0 3.850496 1.642141 3.985354 3.7531764l4.356733 80.9782676c.148948 2.311124-1.678679 4.268556-3.985354 4.268556z"
          stroke="#bfc5d1"
          strokeWidth="1.5"
        />
        <path
          d="m102 100.999515c0 1.105888-.895349 2.000485-2 2.000485-1.1046512 0-2-.894597-2-2.000485 0-1.1049184.8953488-1.999515 2-1.999515 1.104651 0 2 .8945966 2 1.999515"
          fill="#bfc5d1"
        />
        <path
          d="m132 100.999515c0 1.105888-.895349 2.000485-2 2.000485-1.10562 0-2-.894597-2-2.000485 0-1.1049184.89438-1.999515 2-1.999515 1.104651 0 2 .8945966 2 1.999515"
          fill="#bfc5d1"
        />
        <g transform="translate(105 114)">
          <path d="m.1832 10.834c0-5.657 4.586-10.243 10.243-10.243s10.243 4.586 10.243 10.243" fill="#fefefe" />
          <path
            d="m.1832 10.834c0-5.657 4.586-10.243 10.243-10.243s10.243 4.586 10.243 10.243"
            stroke="#bfc5d1"
            strokeWidth="1.5"
          />
        </g>
        <g fill="#fff" stroke="#bfc5d1" strokeWidth="1.5">
          <path d="m140.815977 33.8368854-8.652849-8.6521229c-.217504-.2174861-.217504-.5688874 0-.7863735l2.234455-2.2352744c.217505-.2174861.569942-.2174861.787447 0l8.651842 8.6521229c.217504.2174861.217504.5688874 0 .7863735l-2.234455 2.2352744c-.217505.2174861-.568935.2174861-.78644 0z" />
          <path d="m149.957856 44.7424746-13.699548-13.7009812c-.344411-.3433671-.344411-.9005488 0-1.2449692l3.538901-3.5389988c.343358-.3433672.900524-.3433672 1.244935 0l13.699548 13.7009812c.344411.3433671.344411.9005488 0 1.2460225l-3.537848 3.5379455c-.344411.3433672-.901577.3433672-1.245988 0z" />
          <path d="m140 15.9994846c0 8.8367209-7.163564 16.0005154-16 16.0005154s-16-7.1637945-16-16.0005154c0-8.83672087 7.163564-15.9994846 16-15.9994846s16 7.16276373 16 15.9994846z" />
        </g>
        <path
          d="m180.07199 47.9432112h61.92801c-.963383-5.7831699-5.856723-10.1879195-11.763727-10.1879195-1.30831 0-2.563323.2230253-3.740902.6215798-1.187636-7.0273619-7.140898-12.3768715-14.325038-12.3768715-7.050393 0-12.927229 5.1471347-14.266713 11.9824471-1.959951-1.4465669-4.357346-2.3066598-6.955864-2.3066598-6.59887 0-11.947756 5.4919979-11.947756 12.2674239 0 .0196179.003017.0371708.003017.0567888.351966-.0351058.707955-.0567888 1.068973-.0567888"
          fill="url(#d)"
          opacity=".924456"
        />
      </g>
    </SvgIcon>
  );
};

export const NoDataIcon = withStyles(styles)(NoDataIconComponent);
