import { createStyles } from '@material-ui/core/styles';

export default function styles() {
  return createStyles({
    root: {
      minWidth: 180,
      height: 'auto',
    },
  });
}
