import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { colors } from '@core/theme/units/palette';
import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Zap.styles';

interface ZapIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const ZapIconComponent: FC<ZapIconProps> = ({ classes, color = colors.white.lightest }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M5.49999 1L1.04672 6.34393C0.872316 6.55321 0.785114 6.65785 0.783781 6.74623C0.782622 6.82306 0.816858 6.89615 0.87662 6.94445C0.945366 7 1.08158 7 1.35401 7H4.99999L4.49999 11L8.95326 5.65607C9.12767 5.44679 9.21487 5.34215 9.2162 5.25377C9.21736 5.17694 9.18313 5.10385 9.12336 5.05555C9.05462 5 8.9184 5 8.64598 5H4.99999L5.49999 1Z"
        stroke={color}
        fill={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const ZapIcon = withStyles(styles)(ZapIconComponent);
