import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './KatoLogo.styles';

interface KatoLogoIconProps extends WithStyles<typeof styles> {
  color?: string;
  onClick?: () => void;
}

const KatoLogoIconComponent: FC<KatoLogoIconProps> = ({ classes, color = '#FF4A6B', onClick }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="35"
      viewBox="0 0 100 35"
      fill="none"
      classes={{ root: classes.root }}
      onClick={onClick}
    >
      <path d="M22.9747 17.0114L29.1401 0L12.2213 9.87605V24.1449L29.1401 34.0209L22.9747 17.0114Z" fill={color} />
      <path d="M12.2214 0.976562H0V17.0114L12.2214 9.87602V0.976562Z" fill={color} />
      <path d="M0 33.0447H12.2214V24.1452L0 17.0117V33.0447Z" fill={color} />
      <path
        d="M40.6003 6.83105H44.9025V15.9722L52.9335 6.83105H58.1536L50.7255 15.2517L58.6417 27.0176H53.4789L47.6852 18.4239L44.9035 21.5961V27.0176H40.6012V6.83105H40.6003Z"
        fill={color}
      />
      <path
        d="M73.8715 23.5855V27.0168H71.8063C69.7127 27.0168 69.0527 26.0069 69.0819 24.5944C68.0496 26.2386 66.5577 27.1898 64.2634 27.1898C61.0802 27.1898 58.7856 25.6619 58.7856 22.8357C58.7856 19.6919 61.1376 17.9039 65.5546 17.9039H68.4801V17.1825C68.4801 15.856 67.5338 14.9908 65.8414 14.9908C64.2634 14.9908 63.2027 15.7123 63.0305 16.8071H59.1298C59.4166 13.9233 62.0553 12.02 65.9561 12.02C70.0863 12.02 72.4663 13.8657 72.4663 17.4132V22.6617C72.4663 23.4399 72.7824 23.5845 73.4133 23.5845H73.8722L73.8715 23.5855ZM68.4794 20.6157H65.5254C63.8337 20.6157 62.8585 21.3078 62.8585 22.5474C62.8585 23.5855 63.6898 24.2492 65.0383 24.2492C67.1608 24.2492 68.451 22.9227 68.4801 20.904V20.6157H68.4794Z"
        fill={color}
      />
      <path
        d="M76.051 15.6264H73.6135V12.1951H76.051V8.04248H80.0961V12.1951H83.4805V15.6264H80.0961V22.1154C80.0961 23.1536 80.4975 23.5857 81.5582 23.5857H83.5658V27.0169H80.268C77.4569 27.0169 76.051 25.6033 76.051 22.7782V15.6264Z"
        fill={color}
      />
      <path
        d="M84.4546 19.6059C84.4546 15.0495 87.5229 12.022 92.2266 12.022C96.931 12.022 100 15.0505 100 19.6059C100 24.1613 96.9317 27.1898 92.2266 27.1898C87.5222 27.1898 84.4546 24.1623 84.4546 19.6059ZM95.898 19.6059C95.898 17.0965 94.4922 15.3379 92.2266 15.3379C89.9617 15.3379 88.585 17.0975 88.585 19.6059C88.585 22.1143 89.9617 23.8739 92.2266 23.8739C94.4922 23.8739 95.898 22.1153 95.898 19.6059Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export const KatoLogoIcon = withStyles(styles)(KatoLogoIconComponent);
