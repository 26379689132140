import { SvgIcon } from '@material-ui/core';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Filters.styles';

export interface FiltersIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const FiltersIconComponent: React.FC<FiltersIconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path d="M4 8H12M2 4H14M6 12H10" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const FiltersIcon = withStyles(styles)(FiltersIconComponent);
