import { createStyles } from '@material-ui/core/styles';

import { colors } from '@core/theme/units/palette';

export const styles = () =>
  createStyles({
    root: {
      fontSize: 14,
      fill: 'transparent',
      color: colors.white.lightest,
    },
  });
